<template>
  <div class="admin">
    <aside>
      <yl-button
        type="clean"
        text="bold"
        class="button-style"
        :class="{'active': $route.path === '/admin/main'}"
        @click="$router.push('/admin/main')"
      >
        首页
      </yl-button>
      <yl-button
        type="clean"
        text="bold"
        class="button-style"
        :class="{'active': $route.path === '/admin/subject-management'}"
        @click="$router.push('/admin/subject-management')"
      >
        受试者管理
      </yl-button>
      <yl-button
        type="clean"
        text="bold"
        class="button-style"
        :class="{'active': $route.path === '/admin/subject-data'}"
        @click="$router.push('/admin/subject-data')"
      >
        受试者上报数据
      </yl-button>
      <yl-button
        type="clean"
        text="bold"
        class="button-style"
        :class="{'active': $route.path === '/admin/center-qr'}"
        @click="$router.push('/admin/center-qr')"
      >
        中心二维码
      </yl-button>
    </aside>
    <div class="views">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class TheAdmin extends Vue {}
</script>

<style lang="scss" scoped>
aside {
  background-color: white;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 5px;
  min-height: calc(100vh - 75px - 40px);

  .button-style {
    width: 100%;
    text-align: left;
  }
}
.admin {
  display: flex;
}
.views {
  width: calc(100% - 200px);
}
</style>
